import { ColumnFlex, RowFlex, SubContainerX, Subheader } from '@ifca-ui/core'
import { PxLetterB } from '@ifca-ui/icons/dist/index.esm'
import { Functions, MonetizationOn } from '@mui/icons-material'
import { amtStr } from 'helpers/numFormatter'
import TextX from './TextX'
import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import TextS from './TextS'

export const SalesContractSubheader = ({ getSalesContractById }: any) => {
  return (
    <>
      <SubContainerX>
        <Subheader>
          <RowFlex
            className="text-icon text-desc"
            fullWidth
            mainAxisAlignment="space-between"
          >
            <ColumnFlex gap={'2px'}>
              <Tooltip title="Unit No." placement="right">
                <div>
                  <RowFlex gap={'2px'} className="text-xsTitle">
                    <TextS data={getSalesContractById?.unit?.unitNo} />
                    <div>
                      {getSalesContractById?.unit?.isBumi && <PxLetterB />}
                    </div>
                  </RowFlex>
                </div>
              </Tooltip>
              <Tooltip
                title={
                  !!getSalesContractById?.isCompany
                    ? 'Company Name'
                    : 'Purchaser Name'
                }
                placement="right"
              >
                <div>
                  <TextS
                    data={
                      !!getSalesContractById?.isCompany
                        ? `${getSalesContractById?.contact?.companyName} (${getSalesContractById?.contact?.fullName})`
                        : getSalesContractById?.contact?.fullName
                    }
                    textStyle={{ color: '#757575' }}
                  />
                </div>
              </Tooltip>
            </ColumnFlex>

            <ColumnFlex gap={'2px'}>
              <TextS
                tooltip={{ title: 'Book Date', placement: 'right' }}
                data={getSalesContractById?.bookDate}
                dataType="Date"
                color="#757575"
              />

              <TextS
                tooltip={{
                  title: getSalesContractById?.spaDate
                    ? 'SPA Date'
                    : 'Expected Signed Date',
                  placement: 'right',
                }}
                data={
                  getSalesContractById?.spaDate ??
                  getSalesContractById?.expectedSnpDate
                }
                dataType="Date"
                alt="-"
                color={getSalesContractById?.spaDate ? '#757575' : 'red'}
              />
            </ColumnFlex>

            <ColumnFlex gap={'2px'}>
              <Tooltip title="SPA Price" placement="left">
                <div>
                  <RowFlex gap={'2px'} className="c-orange">
                    <MonetizationOn />
                    <div> {amtStr(getSalesContractById?.sellingPrice)}</div>
                  </RowFlex>
                </div>
              </Tooltip>

              <Tooltip title="Total Selling Price" placement="left">
                <div>
                  <RowFlex gap={'2px'} className="c-orange">
                    <Functions />
                    <div>
                      {amtStr(
                        getSalesContractById?.saleExtension?.totalSalesValue ??
                          getSalesContractById?.sellingPrice
                      )}
                    </div>
                  </RowFlex>
                </div>
              </Tooltip>
            </ColumnFlex>
          </RowFlex>
        </Subheader>
      </SubContainerX>
    </>
  )
}
