import { yupResolver } from '@hookform/resolvers/yup'
import { ColumnFlex, Container, Footer, RowGrid } from '@ifca-ui/core'
import { Card, CardContent } from '@mui/material'
import BlockFloorUnitSelection from 'components/General/BlocksFloorsUnitsSelection'
import DropdownButton from 'components/Inputs/DropdownButton'
import { TextFormField } from 'components/Inputs/InputFormField'
import { NumberFormField } from 'components/Inputs/NumberFormField'
import useHeader from 'helpers/Hooks/useHeader'
import { CommonYupValidation } from 'helpers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import * as yup from 'yup'
import { ProjectSubHeader } from '../../../../components/General/ProjectSubHeader'
import {
  computationModeList,
  useAddPriceAdjustment,
  useEditPriceAdjustment,
  useGetPriceAdjustmentById,
  useGetReasonList,
  useGetRounding,
  useGetSelectUnitAtPriceAdjustment,
} from './GraphQL/UnitPricingBloc'

export interface UnitPriceInput {
  id: string
  title: string
  roundingMode: any
  roundingPrecision: any
  reason: any
  computationMode: any
  computationValue: number
  remark?: string
}

interface listingProps {
  id: string
  name: string
}

export const UnitPricingForm = () => {
  const { projectID, id }: any = useParams()

  useHeader({
    current: 'Unit Pricing',
    maxItems: 3,
    rightText: id ? 'Edit' : 'New',
  })

  const schema = yup.object().shape({
    title: CommonYupValidation.requireField('Title is required'),
    roundingMode: CommonYupValidation.requireArray('Rounding Mode is required'),
    roundingPrecision: CommonYupValidation.requireArray(
      'Rounding Precision is required'
    ),
    reason: CommonYupValidation.requireArray('Reason is required'),
    computationMode: CommonYupValidation.requireArray(
      'Computation Mode is required'
    ),
    computationValue: CommonYupValidation.requireField(
      'Computation Value is required'
    ),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<UnitPriceInput>({
    defaultValues: {
      title: '',
      roundingPrecision: null,
      roundingMode: null,
      computationMode: null,
      computationValue: null,
      reason: null,
      remark: '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  })

  const { addData, isLoadingAdd } = useAddPriceAdjustment()
  const { editData, isLoadingEdit } = useEditPriceAdjustment()

  const {
    priceAdjustment,
    priceAdjustmentByIdLoading,
    fetchPriceAdjustmentData,
  } = useGetPriceAdjustmentById(reset)

  const { fetchSelectUnitList, unitData, isFetchingUnits } =
    useGetSelectUnitAtPriceAdjustment()

  const { fetchReasonList, reasonList } = useGetReasonList()
  const onSubmit = (data: UnitPriceInput) => submitApi(data, 'SUBMITTED')
  const saveAsDraft = (data: UnitPriceInput) => submitApi(data, 'DRAFT')
  const submitApi = (data: UnitPriceInput, status: string) => {
    if (id) {
      editData(data, status, selectedUnit)
    } else {
      addData(data, status, selectedUnit)
    }
  }

  const { fetchRounding, roundingModeListing, roundingPrecisionListing } =
    useGetRounding()

  useEffect(() => {
    fetchReasonList()
    fetchSelectUnitList(id ? id : null)
    fetchRounding()
    if (id) {
      fetchPriceAdjustmentData(id)
    }
  }, [])

  const [selectedUnit, setSelectedUnit] = useState<any>([])

  return (
    <>
      <ProjectSubHeader />
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <Container>
          <ColumnFlex gap={'10px'}>
            <Card>
              <CardContent>
                <TextFormField
                  control={control}
                  label="Title"
                  name="title"
                  errors={errors}
                />

                <RowGrid gridTemplateColumns={'1fr 1fr'} gap="10px">
                  <DropdownButton
                    control={control}
                    label="Rounding Mode"
                    name="roundingMode"
                    errors={errors}
                    optionLabel={'name'}
                    listData={roundingModeListing}
                  />
                  <DropdownButton
                    control={control}
                    label="Rounding Precision"
                    name="roundingPrecision"
                    errors={errors}
                    optionLabel={'name'}
                    listData={roundingPrecisionListing}
                  />
                </RowGrid>
                {reasonList && (
                  <DropdownButton
                    control={control}
                    label="Reason"
                    name="reason"
                    errors={errors}
                    optionLabel={'name'}
                    listData={reasonList}
                  />
                )}

                <DropdownButton
                  control={control}
                  label="Computation Mode"
                  name="computationMode"
                  errors={errors}
                  optionLabel={'name'}
                  listData={computationModeList}
                />
                <NumberFormField
                  control={control}
                  label="Value"
                  name="computationValue"
                  errors={errors}
                />
                <TextFormField
                  control={control}
                  label="Remark"
                  name="remark"
                  errors={errors}
                  isRequired={false}
                />
              </CardContent>
            </Card>
            {unitData && (
              <Card>
                <CardContent>
                  <BlockFloorUnitSelection
                    blockDetails={unitData}
                    setSelectedUnit={setSelectedUnit}
                    existingSelectedUnit={
                      id && priceAdjustment?.priceAdjustmentUnit
                        ? priceAdjustment?.priceAdjustmentUnit?.map(
                            x => x.unitId
                          )
                        : []
                    }
                  />
                </CardContent>
              </Card>
            )}
          </ColumnFlex>
        </Container>
      </form>
      <Footer
        buttons={[
          {
            children: 'Save as Draft',
            color: 'primary',
            disabled: isLoadingAdd,
            onClick: () => {
              handleSubmit(saveAsDraft)()
            },
          },
          {
            children: 'Submit',
            color: 'primary',
            disabled: isLoadingAdd,
            onClick: () => {
              handleSubmit(onSubmit)()
            },
          },
        ]}
      />
      {/* <Footer
        buttons={[
          {
            children: 'Save as Draft',
            color: 'primary',
            disabled: isLoadingAdd,
            onClick: () => {
              handleSubmit(saveAsDraft)()
            },
          },
          {
            children: 'Submit',
            color: 'primary',
            disabled: isLoadingAdd,
            onClick: () => {
              handleSubmit(onSubmit)()
            },
          },
        ]}
      /> */}
    </>
  )
}
