import { getUserProfile } from 'UserData'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'

interface props {
  backPath?: string
  current: string
  maxItems: number
  rightText?: string
}

const useHeader = ({ backPath, current, maxItems, rightText }: props) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { navBack } = useNav()

  const navigate = useNavigate()

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: { onClick: () => (backPath ? navigate(backPath) : navBack()) },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${getUserProfile()?.subscriptionName}`,
        },
        bottomSection: {
          breadcrumbs: {
            current: current,
            maxItems: maxItems,
          },
          rightText: rightText,
        },
      },
    })
  }, [])

  return <></>
}
export default useHeader
