import { Reducer, useEffect, useReducer, useRef, useState } from 'react'
import { IAction } from '../models'
import { useCustomCountryQuery } from './useCustomCountryQuery'

// Load Google APi key and callback function
let autoComplete: any
let autoComplete2: any
const loadScript = (url: any, callback: any) => {
  let script: any = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  // if (isLoadedScript(url)) {
  document.getElementsByTagName('head')[0].appendChild(script)
  // }
}

// Detect if library loaded
const isLoadedScript = lib => {
  return document.querySelectorAll('[src="' + lib + '"]').length > 0
}

interface AddressAutoCompleteProps {
  address: string
  country: any
  state: any
  city: any
  postcode: any
}
const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY

export const useAddressAutoComplete = () => {
  const [editVal, setEditVal] = useState(null)
  const [query, setQuery] = useState<String>('')
  const [query2, setQuery2] = useState<String>('')
  const {
    getCountry, // country
    getStateByCountry, // State
    getCityByState, // City
    getPostCodeByCity, // Postcode
    handleGetStateByCountry, // variables : CountryID
    handleGetCityByState, // variables : StateID
    handleGetPostcodeByCity, // variables : StateID
  } = useCustomCountryQuery()

  const {
    getCountry: getCountry2, // country
    getStateByCountry: getStatebyCountry2, // State
    getCityByState: getCitybyState2, // City
    getPostCodeByCity: getPostcodebyCity2, // Postcode
    handleGetStateByCountry: handleGetStateByCountry2, // variables : CountryID
    handleGetCityByState: handleGetCityByState2, // variables : StateID
    handleGetPostcodeByCity: handleGetPostcodeByCity2, // variables : StateID
  } = useCustomCountryQuery()

  //autocomplete Ref for input field
  const autoCompleteRef = useRef(null)
  const autoCompleteRef2 = useRef(null)
  const initialState: AddressAutoCompleteProps = {
    address: '',
    country: {},
    state: {},
    city: {},
    postcode: {},
  }

  const initialState2: AddressAutoCompleteProps = {
    address: '',
    country: {},
    state: {},
    city: {},
    postcode: {},
  }
  const reducer: Reducer<AddressAutoCompleteProps, IAction> = (
    state,
    action
  ) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }

  const reducer2: Reducer<AddressAutoCompleteProps, IAction> = (
    state,
    action
  ) => {
    switch (action.type) {
      case 'reset':
        return initialState2
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [state2, dispatch2] = useReducer(reducer2, initialState2)
  const handlePlaceSelect = async (updateQuery: any) => {
    // Address object {} getPlace  //https://developers.google.com/maps/documentation/javascript/reference/places-widget
    const addressObject = autoComplete.getPlace()
    const addressName = addressObject.name
    let query: any

    if (addressObject.formatted_address.includes(addressName)) {
      query = addressObject.formatted_address
    } else {
      query = addressName + ', ' + addressObject.formatted_address
    }

    updateQuery(query)
    const addressComponent: Array<any> = addressObject['address_components']
    let PostCode = {
        postcode: '',
      },
      City = {
        name: '',
      },
      State = {
        name: '',
      },
      Country = {
        name: '',
      }

    // Address component for State , City , Post Code value
    addressComponent.forEach(x => {
      if (x.types.includes('locality')) {
        City.name = x.long_name
      } else if (x.types.includes('administrative_area_level_1')) {
        State.name = x.long_name
      } else if (x.types.includes('country')) {
        Country.name = x.long_name
      } else if (x.types.includes('postal_code')) {
        PostCode.postcode = x.long_name
      }
    })
    // join 4 object short_name
    let addressResult: Array<any> = [{ name: addressName }]
    addressComponent.forEach(x =>
      x.types.filter(y => {
        if (
          (y === 'premise' ||
            y === 'subpremise' ||
            y === 'route' ||
            y === 'sublocality_level_1') &&
          !x.long_name.match(addressName)
        )
          return addressResult.push({ name: x.long_name })
        else return null
      })
    )

    // Dispatch Object {ID , name | PostCode}
    dispatch({
      type: 'address',
      payload: addressResult.map(x => x.name).join(', '),
    })
    dispatch({
      type: 'country',
      payload: Country,
    })
    dispatch({
      type: 'state',
      payload: State,
    })
    dispatch({
      type: 'city',
      payload: City,
    })
    dispatch({
      type: 'postcode',
      payload: PostCode,
    })
  }

  const handlePlaceSelect2 = async (updateQuery2: any) => {
    // Address object {} getPlace  //https://developers.google.com/maps/documentation/javascript/reference/places-widget
    const addressObject = autoComplete2.getPlace()
    const addressName = addressObject.name
    let query: any

    if (addressObject.formatted_address.includes(addressName)) {
      query = addressObject.formatted_address
    } else {
      query = addressName + ', ' + addressObject.formatted_address
    }

    updateQuery2(query)
    const addressComponent: Array<any> = addressObject['address_components']
    let PostCode = {
        postcode: '',
      },
      City = {
        name: '',
      },
      State = {
        name: '',
      },
      Country = {
        name: '',
      }

    // Address component for State , City , Post Code value
    addressComponent.forEach(x => {
      if (x.types.includes('locality')) {
        City.name = x.long_name
      } else if (x.types.includes('administrative_area_level_1')) {
        State.name = x.long_name
      } else if (x.types.includes('country')) {
        Country.name = x.long_name
      } else if (x.types.includes('postal_code')) {
        PostCode.postcode = x.long_name
      }
    })
    // join 4 object short_name
    let addressResult: Array<any> = [{ name: addressName }]
    addressComponent.forEach(x =>
      x.types.filter(y => {
        if (
          (y === 'premise' ||
            y === 'subpremise' ||
            y === 'route' ||
            y === 'sublocality_level_1') &&
          !x.long_name.match(addressName)
        )
          return addressResult.push({ name: x.long_name })
        else return null
      })
    )

    // Dispatch Object {ID , name | PostCode}
    dispatch2({
      type: 'address',
      payload: addressResult.map(x => x.name).join(', '),
    })
    dispatch2({
      type: 'country',
      payload: Country,
    })
    dispatch2({
      type: 'state',
      payload: State,
    })
    dispatch2({
      type: 'city',
      payload: City,
    })
    dispatch2({
      type: 'postcode',
      payload: PostCode,
    })
  }

  const handleScriptLoad = (
    updateQuery: any,
    updateQuery2: any,
    autoCompleteRef: any,
    autoCompleteRef2: any
  ) => {
    autoComplete = new (window as any).google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {}
    )
    autoComplete.setFields(['address_components', 'formatted_address', 'name'])
    autoComplete.addListener('place_changed', () => {
      handlePlaceSelect(updateQuery)
    })

    autoComplete2 = new (window as any).google.maps.places.Autocomplete(
      autoCompleteRef2.current,
      {}
    )
    autoComplete2.setFields(['address_components', 'formatted_address', 'name'])
    autoComplete2.addListener('place_changed', () => {
      handlePlaceSelect2(updateQuery2)
    })
  }
  useEffect(() => {
    loadGoogleApi()
  }, [])

  const loadGoogleApi = () => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&time=${new Date().toISOString()}`,
      () =>
        handleScriptLoad(setQuery, setQuery2, autoCompleteRef, autoCompleteRef2)
    )
  }

  //#region get state option when page load
  useEffect(() => {
    const countryId = getCountry.find(v => v.name === 'Malaysia')?.ID

    if (state.country?.name === 'Malaysia' && countryId) {
      handleGetStateByCountry({
        variables: {
          countryId: countryId,
        },
      })
    }
    if (state2.country?.name === 'Malaysia' && countryId) {
      handleGetStateByCountry2({
        variables: {
          countryId: countryId,
        },
      })
    }
  }, [state.country?.name, state2.country?.name, getCountry])
  //#endregion

  //#region get city option when page load
  useEffect(() => {
    const stateId = getStateByCountry.find(
      v => v.name === `${state.state?.name}`
    )?.ID

    if (stateId) {
      handleGetCityByState({
        variables: {
          stateId: stateId,
        },
      })

      handleGetCityByState2({
        variables: {
          stateId: stateId,
        },
      })
    }
  }, [state.state?.name, state2.state?.name, getStateByCountry])
  //#endregion

  //#region get postcode option when page load
  useEffect(() => {
    const cityId = getCityByState.find(v => v.name === `${state.city?.name}`)
      ?.ID

    if (cityId) {
      handleGetPostcodeByCity({
        variables: {
          cityId: cityId,
        },
      })

      handleGetPostcodeByCity2({
        variables: {
          cityId: cityId,
        },
      })
    }
  }, [state.city?.name, state2.city?.name, getCityByState])
  //#endregion

  const handleGoogleOnChange = e => {
    setQuery(e)
    dispatch({
      type: 'address',
      payload: e,
    })
  }

  const handleGoogleOnChange2 = e => {
    setQuery2(e)
    dispatch2({
      type: 'address',
      payload: e,
    })
  }

  const handleCountryOnChange = (newValue: any) => {
    dispatch({
      type: 'country',
      payload: newValue,
    })

    if (newValue?.ID) {
      handleGetStateByCountry({
        variables: {
          countryId: newValue?.ID,
        },
      })
    }
  }

  const handleCountryOnChange2 = (newValue: any) => {
    dispatch2({
      type: 'country',
      payload: newValue,
    })
    if (newValue?.ID) {
      handleGetStateByCountry2({
        variables: {
          countryId: newValue?.ID,
        },
      })
    }
  }

  const handleStateOnChange = (newValue: any) => {
    dispatch({
      type: 'state',
      payload: newValue,
    })
    if (newValue?.ID) {
      handleGetCityByState({
        variables: {
          stateId: newValue?.ID,
        },
      })
    }
  }

  const handleStateOnChange2 = (newValue: any) => {
    dispatch2({
      type: 'state',
      payload: newValue,
    })
    if (newValue?.ID) {
      handleGetCityByState2({
        variables: {
          stateId: newValue?.ID,
        },
      })
    }
  }

  const handleCityOnChange = (newValue: any) => {
    dispatch({
      type: 'city',
      payload: newValue,
    })
    if (newValue?.ID) {
      handleGetPostcodeByCity({
        variables: {
          cityId: newValue?.ID,
        },
      })
    }
  }

  const handleCityOnChange2 = (newValue: any) => {
    dispatch2({
      type: 'city',
      payload: newValue,
    })
    if (newValue?.ID) {
      handleGetPostcodeByCity2({
        variables: {
          cityId: newValue?.ID,
        },
      })
    }
  }

  const handlePostCodeOnChange = (newValue: any) => {
    dispatch({
      type: 'postcode',
      payload: newValue,
    })
  }

  const handlePostCodeOnChange2 = (newValue: any) => {
    dispatch2({
      type: 'postcode',
      payload: newValue,
    })
  }

  const initializeEditValue = (props: any) => {
    dispatch({
      type: 'address',
      payload: props.address,
    })
    dispatch({
      type: 'country',
      payload: { name: props.country },
      // payload: props.country,
    })
    dispatch({
      type: 'state',
      payload: { name: props.state },
    })
    dispatch({
      type: 'city',
      payload: { name: props.city },
    })
    dispatch({
      type: 'postcode',
      payload: { postcode: props.postCode },
    })
  }

  const initializeEditValue2 = (props: any) => {
    dispatch2({
      type: 'address',
      payload: props.address,
    })
    dispatch2({
      type: 'country',
      payload: { name: props.country },
      // payload: props.country,
    })
    dispatch2({
      type: 'state',
      payload: { name: props.state },
    })
    dispatch2({
      type: 'city',
      payload: { name: props.city },
    })
    dispatch2({
      type: 'postcode',
      payload: { postcode: props.postCode },
    })
  }

  return {
    state,
    autoCompleteRef,
    query,
    setQuery,
    setEditVal,
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    getCountry, // country
    getStatebyCountry: getStateByCountry, // State
    getCitybyState: getCityByState, // City
    getPostcodebyCity: getPostCodeByCity, // Postcode
    dispatch,
    initializeEditValue,
    autoCompleteRef2,
    query2,
    setQuery2,
    dispatch2,
    state2,
    handleGoogleOnChange2,
    handleCountryOnChange2,
    handleStateOnChange2,
    handleCityOnChange2,
    handlePostCodeOnChange2,
    getCountry2, // country
    getStatebyCountry2, // State
    getCitybyState2, // City
    getPostcodebyCity2, // Postcode
    initializeEditValue2,
    loadGoogleApi,
  }
}
