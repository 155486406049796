import TextField from '@mui/material/TextField'
import { customFormatNumber } from 'helpers/arraySum'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

interface NumberFormFieldProps {
  control: any
  errors?: any
  isRequired?: boolean
  label: string
  name: string
  margin?: 'none' | 'dense' | 'normal'
  minValue?: number
  maxValue?: number
}

export const NumberFormField = ({
  control,
  errors,
  isRequired = true,
  label,
  name,
  margin = 'normal',
  minValue,
  maxValue,
}: NumberFormFieldProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <NumberFormat
          allowNegative={false}
          autoComplete="off"
          customInput={TextField}
          decimalScale={2}
          error={!!errors?.[name]}
          fixedDecimalScale
          fullWidth
          helperText={errors?.[name]?.message}
          // isAllowed={isAllowed}
          label={label}
          margin={margin}
          multiline
          name={name}
          onBlur={onBlur}
          onChange={event => {
            const newValue = customFormatNumber(event.target.value)
            onChange(newValue)
          }}
          thousandSeparator
          value={value || ''}
          variant="standard"
          required={isRequired}
        />
      )}
    />
  )
}
