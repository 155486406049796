import { ColumnFlex, RowFlex, RowGrid } from '@ifca-ui/core'
import { PxHumanArrow } from '@ifca-ui/icons/dist/index.esm'
import {
  AccountBalance,
  CalendarMonthRounded,
  Person,
} from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Tooltip } from '@mui/material'
import IconX from 'components/General/IconX'
import TextS from 'components/General/TextS'
import moment from 'moment'
import PBnumberSquare from './PBnumberSquare'

interface PBexpansionSummaryProps {
  item: any
  haveSubData: boolean
  isPA: boolean
  handleDelete: any
  lastIndex: number
  index: number
}

const PBexpansionSummary: React.FC<PBexpansionSummaryProps> = ({
  item,
  haveSubData,
  isPA,
  handleDelete,
  lastIndex,
  index,
}: PBexpansionSummaryProps) => {
  return (
    <RowFlex fullWidth crossAxisAlignment="center" gap="5px">
      <ColumnFlex gap={'4px'} fullWidth className="text-desc text-icon">
        <RowFlex key="firstLine" mainAxisAlignment="space-between" fullWidth>
          <RowFlex key="left" gap={'4px'}>
            <PBnumberSquare data={item} />
            <TextS className="pbTitle" data={item.paymentPlanDetailTitle} />
          </RowFlex>
          <RowFlex key="right" className="c-orange">
            {item.details[0].isStakeholder && <IconX icon={PxHumanArrow} />}
            <TextS
              data={item.totalDocumentAmt}
              dataType={'Number'}
              tooltip={{
                title: 'Document Amount',
                placement: 'left',
              }}
              className="text-icon"
              color="primary"
            />
          </RowFlex>
        </RowFlex>
        <RowFlex key="secondLine" mainAxisAlignment="space-between" fullWidth>
          {haveSubData ? (
            <div></div>
          ) : (
            <RowFlex key="left">
              <IconX icon={CalendarMonthRounded} />
              <RowFlex gap={'4px'}>
                {item.details[0].documentDate ? (
                  <div>
                    <TextS
                      data={moment(item.details[0].documentDate).format(
                        'DD MMM yyyy'
                      )}
                      tooltip={{ title: 'Date of Document' }}
                    />
                  </div>
                ) : (
                  <div>-</div>
                )}
                <div>|</div>
                <TextS
                  data={item.details[0].documentNo}
                  tooltip={{ title: 'Document No.', placement: 'right' }}
                />
              </RowFlex>
            </RowFlex>
          )}
          <div style={{ color: 'red' }}>
            <RowFlex key="right">
              {!item?.details.every(x => x?.documentNo || x?.documentDate) ||
              item?.totalOutstanding === 0 ? (
                <span>-</span>
              ) : (
                <TextS
                  data={item?.totalOutstanding}
                  dataType="Number"
                  tooltip={{
                    title: 'Outstanding Amount',
                    placement: 'left',
                  }}
                  color="red"
                />
              )}
            </RowFlex>
          </div>
        </RowFlex>
        {haveSubData ? (
          <RowFlex mainAxisAlignment="space-between" fullWidth>
            <div></div>
            <RowFlex gap="4px">
              <div>No. of installment :</div>
              <div className="underline c-orange">{item.details.length}</div>
            </RowFlex>
          </RowFlex>
        ) : (
          <RowFlex key="thirdLine" mainAxisAlignment="space-between" fullWidth>
            {item.details[0].paymentParty === 'Buyer' ? (
              <RowGrid fullWidth gridTemplateColumns={'1fr'}>
                <RowFlex fullWidth className="truncate-text">
                  <Person sx={{ verticalAlign: 'text-top' }} />
                  {item.details[0].creditDetails?.length > 0 ? (
                    item.details[0].creditDetails.map((no, index, arr) => (
                      <>
                        <TextS
                          className={'text-desc'}
                          data={no?.documentDate}
                          dataType="Date"
                        />
                        &nbsp;|&nbsp;
                        <TextS className={'text-desc'} data={no?.documentNo} />
                        {index !== arr.length - 1 && ','}&nbsp;
                      </>
                    ))
                  ) : (
                    <span>-</span>
                  )}
                </RowFlex>
              </RowGrid>
            ) : (
              <Tooltip
                title={`${item.details[0].paymentParty ?? '-'} (${
                  item.details[0].paymentPartyCode ?? '-'
                })`}
                id="pbTooltip"
                placement="top-start"
              >
                <div>
                  <RowGrid fullWidth gridTemplateColumns={'1fr'}>
                    <RowFlex fullWidth className="truncate-text">
                      <AccountBalance sx={{ verticalAlign: 'text-top' }} />
                      {item.details[0].creditDetails?.length > 0 ? (
                        item.details[0].creditDetails.map((no, index, arr) => (
                          <>
                            <TextS
                              className={'text-desc'}
                              data={no?.documentDate}
                              dataType="Date"
                            />
                            &nbsp;|&nbsp;
                            <TextS
                              className={'text-desc'}
                              data={no?.documentNo}
                            />
                            {index !== arr.length - 1 && ','}&nbsp;
                          </>
                        ))
                      ) : (
                        <span>-</span>
                      )}
                    </RowFlex>
                  </RowGrid>
                </div>
              </Tooltip>
            )}
          </RowFlex>
        )}
      </ColumnFlex>
      {item.allow_reversal &&
        !item.isReversal &&
        isPA &&
        item.details[0].documentNo &&
        index == lastIndex &&
        !item.system_use && (
          <IconX icon={DeleteIcon} color="red" onClick={handleDelete} />
        )}
    </RowFlex>
  )
}

export default PBexpansionSummary
