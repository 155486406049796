import useAppData from 'components/General/useAppData'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import { useProjectDetailQuery } from 'generated/graphql'
import { useEffect } from 'react'
import { useSalesContractById } from './PAprogressBillingBloc'
import useNav from 'components/RouteService/useNav'

const usePBheader = isPA => {
  const { projectID, rootState, subHeaderRef, saleID, rootDispatch, navigate } =
    useAppData()
  const { salesContract } = useSalesContractById()
  const { navBack, navTo } = useNav()

  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: isPA ? 'back' : 'close',
          props: {
            onClick: () => navBack(),
            // isPA
            //   ? navTo(
            //       `/${projectID}/purchaser-accounts`,
            //       {},
            //       {
            //         state: {
            //           saleId: saleID,
            //         },
            //       }
            //     )
            //   : navTo(`/${projectID}/sales-contracts/${saleID}/submenu`),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: isPA ? 4 : 2,
            current: 'Progress Billing',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail, subHeaderRef, salesContract])
}
export default usePBheader
