import { yupResolver } from '@hookform/resolvers/yup'
import {
  ColumnFlex,
  Container,
  EmptyState,
  List,
  ListItem,
  ListItemText,
  RowFlex,
  RowGrid,
  SubContainerX,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import {
  HouseOutlined,
  KeyboardArrowRight,
  Person,
  Phone,
} from '@mui/icons-material'
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import HideX from 'components/General/HideX'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetContactListLazyQuery,
  useGetPAsummaryLazyQuery,
  useGetUnitListLazyQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/yup'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useLocation, useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import usePAdata from './usePAdata'
interface IPAmoduleForm {
  purchaser: any
  unit: any
}
const PurchaserAccountsSubmenu = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  let navigate = useNavigate()
  const { navBack, navTo, navData } = useNav()
  let location = useLocation()
  let stateData: any = location?.state
  const { projectID }: any = useParams()
  const [showPage, setShowPage] = useState<boolean>(false)
  const path = `/${projectID}/purchaser-accounts`
  const [saleID, setSaleID] = useState<string>('')
  const [contactId, setContactId] = useState(null)
  const [unitId, setUnitId] = useState(null)
  const [purchaserDetail, setPurchaserDetail] = useState(null)
  //#region GraphQL
  const { data: { projectDetail } = { projectDetail: null } } =
    useProjectDetailQuery({ variables: { id: projectID } })

  const [
    fetchPAmoduleSummary,
    {
      loading: loadingPAmoduleSummary,
      data: { getPAsummary } = {
        getPAsummary: null,
      },
    },
  ] = useGetPAsummaryLazyQuery({
    onCompleted: data => {
      setShowPage(true)
      // setSaleID(data.getPAsummary.purchaserDetail.saleId)
      if (navData || stateData?.saleId != null) {
        setValue('purchaser', data.getPAsummary?.contactData)
        setValue('unit', data.getPAsummary?.unitData)
        setContactId(data.getPAsummary?.contactData?.contactId)
        setUnitId(data.getPAsummary?.unitData.unitId)
        setPurchaserDetail(data.getPAsummary?.contactData)
      }
    },
  })

  const [
    getContactListData,
    { data: { getContactList = [] } = {}, loading: getContactListLoading },
  ] = useGetContactListLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (unitId == null) {
        setValue('unit', null)
        setUnitId(null)
      }
      if (data.getContactList.length === 1) {
        setValue('purchaser', data.getContactList[0])
        setContactId(data.getContactList[0].contactId)
      } else {
        let contact = data.getContactList.find(x => x.contactId === contactId)
        setValue('purchaser', contact)
      }
    },
  })

  const [
    getUnitListData,
    {
      data: { getUnitList = [] } = {},
      loading: getUnitListLoading,
      error: getUnitListError,
    },
  ] = useGetUnitListLazyQuery({
    onCompleted: data => {
      if (contactId == null) {
        setValue('purchaser', null)
        setContactId(null)
      }

      if (data.getUnitList.length === 1) {
        setValue('unit', data.getUnitList[0])
        setSaleID(data.getUnitList[0].saleId)
        setUnitId(data.getUnitList[0].unitId)
      } else {
        setValue('unit', null)
        setUnitId(null)
      }
    },
  })

  //#endregion

  //#region header/subheader
  useEffect(() => {
    getContactListData({ variables: { unitId: '' } })
    getUnitListData({ variables: { contactId: '' } })

    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
            // navTo(`/${projectID}/sales-cancellation/form/add`)
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Purchaser Accounts',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  //#endregion

  const indexBackgroundColor = data => {
    if (data.gotArchitectCert && data.documentNo == null) return '#ff9800'
    if (!data.gotArchitectCert) return 'grey'
    if (data.documentNo != null) return 'blue'
  }
  const indexWithCircle = (backgraoundColor, textColor, index) => {
    return (
      <Typography
        className="text-desc orange-circle-number"
        component="span"
        variant="inherit"
        style={{
          backgroundColor: backgraoundColor,
          color: textColor,
          marginRight: '5px',
        }}
      >
        {index}
      </Typography>
    )
  }

  const { submenuList } = usePAdata(getPAsummary, saleID, showPage)

  const PAmoduleFormSchema = yup.object().shape({
    purchaser: CommonYupValidation.requireArray('Purchaser is required.'),
    unit: CommonYupValidation.requireArray('Unit is required.'),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<IPAmoduleForm>({
    defaultValues: {
      purchaser: null,
      unit: null,
    },
    mode: 'onSubmit',
    resolver: yupResolver(PAmoduleFormSchema),
  })

  useEffect(() => {
    if (saleID && unitId) {
      fetchPAmoduleSummary({
        variables: {
          saleId: saleID,
        },
      })
    }
  }, [saleID, unitId])

  useEffect(() => {
    if (stateData?.saleId != null) {
      setSaleID(stateData.saleId)
      fetchPAmoduleSummary({
        variables: {
          saleId: stateData.saleId,
        },
      })
    }
  }, [stateData])

  useEffect(() => {
    if (navData) {
      setSaleID(navData?.['saleId'])
      fetchPAmoduleSummary({
        variables: {
          saleId: navData?.['saleId'],
        },
      })
    }
  }, [navData])

  return (
    <>
      <SubContainerX>
        <Subheader>
          <SubheaderText
            primary={
              <ColumnFlex gap={'5px'}>
                <RowGrid gridTemplateColumns={'1fr 1fr'} gap="10px">
                  <Controller
                    control={control}
                    name="purchaser"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        className="fontSizeSelect"
                        disablePortal
                        fullWidth
                        options={getContactList}
                        getOptionLabel={option =>
                          !!option?.['isCompany']
                            ? `${option?.['companyName']} (${option['purchaserName']})`
                            : option['purchaserName']
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.contactId === value.contactId
                        }
                        renderOption={(props, option) => {
                          return (
                            <>
                              <ListItem
                                key={option?.contactId}
                                style={{ padding: '0' }}
                                {...props}
                              >
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      component="div"
                                      className="text-xsTitle"
                                      variant="inherit"
                                      flex="1"
                                    >
                                      {!!option?.isCompany
                                        ? `${option?.companyName} (${option?.purchaserName})`
                                        : option?.purchaserName}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography component="div">
                                      {!!option?.isCompany ? (
                                        <Typography
                                          component="span"
                                          className="text-icon text-desc"
                                          variant="inherit"
                                          color="#454545"
                                        >
                                          Company Reg No: {option?.companyRegNo}
                                          {!!option.officeTel && (
                                            <>
                                              &nbsp; | &nbsp;
                                              <Phone />
                                              {option.officeTel}
                                            </>
                                          )}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          component="span"
                                          className="text-icon text-desc"
                                          variant="inherit"
                                          color="#454545"
                                        >
                                          IC No: {option?.icNo}
                                          &nbsp; | &nbsp;
                                          <Phone />
                                          {option.phoneNo}
                                        </Typography>
                                      )}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </>
                          )
                        }}
                        onChange={(event, newValue) => {
                          onChange(newValue)
                          setShowPage(false)
                          if (newValue) {
                            let contact = getContactList.find(
                              x => x.contactId === newValue?.contactId
                            )
                            setPurchaserDetail(contact)
                            setContactId(newValue?.contactId)
                            getUnitListData({
                              variables: { contactId: newValue?.contactId },
                            })
                          } else {
                            getContactListData({ variables: { unitId: '' } })
                            getUnitListData({ variables: { contactId: '' } })
                            setContactId(null)
                            setUnitId(null)
                          }
                        }}
                        value={value}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="standard"
                            margin="none"
                            autoComplete="off"
                            value={value}
                            helperText={errors?.purchaser?.message}
                            error={errors?.purchaser ? true : false}
                            required
                            className="selectPAstyle"
                            InputProps={{
                              ...params.InputProps,
                              placeholder: 'Purchaser',
                              className: 'fontSizeSelect',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="unit"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        className="fontSizeSelect"
                        disablePortal
                        fullWidth
                        options={getUnitList.map(option => ({
                          ...option,
                          key: option.unitId + option.contactId,
                        }))}
                        getOptionLabel={option =>
                          `${option?.['unitNo']} (${option?.['unitStatus']})`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.key === value.key
                        }
                        onChange={(event, newValue) => {
                          if (newValue?.saleId === saleID) return
                          onChange(newValue)
                          setShowPage(false)
                          if (newValue) {
                            let contact = getContactList.find(
                              x => x.contactId === newValue?.contactId
                            )
                            setPurchaserDetail(contact)
                            setUnitId(newValue?.unitId)
                            getContactListData({
                              variables: { unitId: newValue?.unitId },
                            })
                            setSaleID(newValue?.saleId)
                            setContactId(newValue?.contactId)
                          } else {
                            getContactListData({ variables: { unitId: '' } })
                            getUnitListData({ variables: { contactId: '' } })
                            setContactId(null)
                            setUnitId(null)
                          }
                        }}
                        value={value}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="standard"
                            margin="none"
                            autoComplete="off"
                            value={value}
                            helperText={errors?.unit?.message}
                            error={errors?.unit ? true : false}
                            required
                            InputProps={{
                              ...params.InputProps,
                              placeholder: 'Unit',
                              className: 'fontSizeSelect',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <HouseOutlined color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </RowGrid>
                {purchaserDetail && contactId && (
                  <RowFlex>
                    <HideX>
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    </HideX>
                    {purchaserDetail?.isCompany ? (
                      <RowFlex className="text-desc">
                        <div>
                          <span>Company Reg No: </span>
                          <span>{purchaserDetail?.companyRegNo}</span>
                        </div>
                        {purchaserDetail.officeTel && (
                          <RowFlex gap="5px">
                            <span></span>
                            <span>|</span>
                            <div className="text-icon">
                              <Phone />
                              {purchaserDetail.officeTel}
                            </div>
                          </RowFlex>
                        )}
                      </RowFlex>
                    ) : (
                      <RowFlex gap="5px" className="text-desc">
                        <span>
                          <span>IC No: </span>
                          {purchaserDetail.icNo}
                        </span>
                        <span>|</span>
                        <div className="text-icon">
                          <Phone />
                          {purchaserDetail.phoneNo}
                        </div>
                      </RowFlex>
                    )}
                  </RowFlex>
                )}
              </ColumnFlex>
            }
          />
        </Subheader>
      </SubContainerX>
      <Container>
        {showPage ? (
          <>
            <List>
              {submenuList?.map(el => {
                return (
                  <ListItem
                    key={el.title}
                    secondaryAction={
                      <IconButton>
                        <KeyboardArrowRight />
                      </IconButton>
                    }
                    onClick={() => {
                      navTo(el?.path, null, { saleId: saleID })
                    }}
                  >
                    <ListItemText
                      primary={
                        <RowFlex fullWidth mainAxisAlignment="space-between">
                          <ColumnFlex key={1}>
                            <Typography
                              component="span"
                              className="text-xsTitle"
                              variant="inherit"
                            >
                              {el?.title}
                            </Typography>
                          </ColumnFlex>
                          <ColumnFlex key={2}>
                            <Tooltip title="Total Charges" placement="left">
                              <Typography
                                component="span"
                                color={el?.rightTextColor}
                                className="text-xsTitle"
                              >
                                {el.amount < 0 && '('}
                                <NumberFormat
                                  value={el?.amount}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                />
                                {el.amount < 0 && ')'}
                              </Typography>
                            </Tooltip>{' '}
                            <Typography
                              component="span"
                              color={el?.rightTextColor}
                              className="text-xsTitle"
                            >
                              {el?.date}
                            </Typography>
                          </ColumnFlex>
                        </RowFlex>
                      }
                      secondary={
                        <RowFlex fullWidth mainAxisAlignment="space-between">
                          <ColumnFlex key={1}>
                            <Typography
                              component="span"
                              className="text-xsTitle"
                              variant="inherit"
                            >
                              {el?.description}
                            </Typography>
                          </ColumnFlex>
                          <ColumnFlex key={2}>
                            <Tooltip title="Total Unallocated" placement="left">
                              <Typography
                                component="span"
                                color={el?.secondRightTextColor}
                                className="text-xsTitle"
                              >
                                {el.secondAmount < 0 && '('}
                                <NumberFormat
                                  value={el?.secondAmount}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                />
                                {el.secondAmount < 0 && ')'}
                              </Typography>
                            </Tooltip>
                          </ColumnFlex>
                        </RowFlex>
                      }
                    />
                  </ListItem>
                )
              })}
            </List>
          </>
        ) : (
          <>
            <EmptyState title="No Record found" />
          </>
        )}
      </Container>
    </>
  )
}

export default PurchaserAccountsSubmenu
