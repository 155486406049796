import {
  Card,
  ColumnFlex,
  Container,
  RowFlex,
  RowGrid,
  SubContainerX,
  Subheader,
} from '@ifca-ui/core'

import { Box, Typography } from '@mui/material'
import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import TextS from 'components/General/TextS'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import GridViewStaggered from 'components/Layout/GridViewStaggered'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetProgressBillingByIdQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { amtStr } from 'helpers/numFormatter'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

interface props {
  isPA: boolean
}

const PAprogressBillingDetail = ({ isPA }: props) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { projectID, saleID, id }: any = useParams()
  let navigate = useNavigate()
  let location = useLocation()
  const editData: any = location?.state?.state
  const { navBack, navTo, navData, navState } = useNav()
  console.log('navState', navState)

  // GraphQL start
  const { data: { projectDetail } = { projectDetail: null } } =
    useProjectDetailQuery({
      variables: {
        id: projectID,
      },
    })

  // Get PAmodule details start
  const {
    data: { getProgressBillingById } = { getProgressBillingById: null },
  } = useGetProgressBillingByIdQuery({
    variables: { id: id },
  })
  //--Get PAmodule details end--
  // GraphQL end
  //#region -- setup header start
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
            // isPA
            //   ?
            //   navigate(
            //         `/${projectID}/purchaser-accounts/${saleID}/progress-billing`
            //       )
            //   :
            //   navigate(
            //     `/${projectID}/sales-contracts/${saleID}/submenu/progress-billing`
            //   ),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 4,
            current: 'Progress Billing',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  return (
    <>
      <SubContainerX>
        <Subheader>
          <ColumnFlex fullWidth>
            <RowFlex mainAxisAlignment="space-between">
              <TextS
                className={'text-xsTitle'}
                data={getProgressBillingById?.title}
                color={'primary'}
              />
              <TextS
                className={'text-desc color-primary-orange'}
                data={navState?.calculatedDocumentAmt}
                dataType="Number"
                tooltip={{
                  title: 'Document Amount',
                  placement: 'left',
                }}
              />
              {/* </div> */}
            </RowFlex>
            <div style={{ borderBottom: '1px solid #fff' }}></div>
            <RowFlex mainAxisAlignment="space-between">
              <TextS
                className={'text-desc'}
                data={getProgressBillingById?.documentNo}
                alt=""
              />
              <TextS
                className="text-desc"
                color="red"
                data={navState?.outstandingAmt}
                dataType="Number"
                tooltip={{
                  title: 'Outstanding Amount',
                  placement: 'left',
                }}
              />
            </RowFlex>
          </ColumnFlex>
        </Subheader>
      </SubContainerX>
      <Container>
        <Card className="card-container-detail">
          <Box className="left">
            <Typography className="label-f">Document Date</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.documentDate
                ? formatDate(getProgressBillingById?.documentDate)
                : '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Due Date</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.dueDate
                ? formatDate(getProgressBillingById?.dueDate)
                : '-'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Credit Term</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.creditTerm}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Interest Rate</Typography>
            <Typography className="value-f">
              {!getProgressBillingById?.interestRate
                ? `-`
                : `${getProgressBillingById?.interestRate} %`}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Principal Amount</Typography>
            <Typography className="value-f">
              {amtStr(getProgressBillingById?.principalAmt)}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Document Amount</Typography>
            <Typography className="value-f">
              {amtStr(navState?.calculatedDocumentAmt)}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Tax Code</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.billingDetailTaxData?.taxSchemeName ??
                '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Tax Rate</Typography>
            <Typography className="value-f">
              {amtStr(
                getProgressBillingById?.billingDetailTaxData?.totalTaxRate
              )}
              %
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Tax Amount</Typography>
            <Typography className="value-f">
              {amtStr(
                getProgressBillingById?.billingDetailTaxData?.totalTaxAmount
              )}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Include Holiday</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.isIncludeHoliday ? 'Yes' : 'No'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Stake holder</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.isStakeholder ? 'Yes' : 'No'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Vp Stage</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.isVpStage ? 'Yes' : 'No'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Document Ref</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.documentRef ?? '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Transaction Type</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.billItem?.name ?? '-'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Void Date</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.voidDate
                ? formatDate(getProgressBillingById?.voidDate)
                : '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Void By</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.voidUserData?.name ?? '-'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Stage Seq No</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.stageSeqNo}
            </Typography>
          </Box>{' '}
          <Box
            width="100%"
            boxSizing="border-box"
            marginBottom="10px"
            marginTop="10px"
          >
            <Typography className="label-f">Remarks</Typography>
            <Typography className="value-f">
              {getProgressBillingById?.description ?? '-'}
            </Typography>
          </Box>
          <GridViewStaggered gridLayout={[2, 3, 2]}>
            <div>
              <Typography className="label-f">Duration Flag</Typography>
              <Typography className="value-f">1</Typography>
            </div>
            <div>
              <Typography className="label-f">Payment Date</Typography>
              <Typography className="value-f">2</Typography>
            </div>
            <div>
              <Typography className="label-f">Amount</Typography>
              <Typography className="value-f">3</Typography>
            </div>
            <div>
              <Typography className="label-f text-icon">Attachment</Typography>

              <Typography
                noWrap
                className="value-f text-underline text-pointer"
              >
                4{' '}
              </Typography>
            </div>
            <div>
              <Typography className="label-f">Reference No</Typography>
              <Typography className="value-f">6</Typography>
            </div>
            <div>
              <Typography className="label-f">Unit Count</Typography>
              <Typography className="value-f">6</Typography>
            </div>
          </GridViewStaggered>
        </Card>
      </Container>
      {navState?.creditDetails?.length > 0 && (
        <CardListHeader
          title={
            <RowGrid gridTemplateColumns="1fr">
              <Typography
                className="text-xsTitle"
                color="primary"
                component="span"
              >
                Allocation Information
              </Typography>
            </RowGrid>
          }
        >
          {navState?.creditDetails?.map((el, index) => (
            <CardListItem
              body={
                <RowGrid gridTemplateColumns="1fr 1fr 1fr" fullWidth gap="6px">
                  <ColumnFlex gap="3px">
                    <TextS
                      className={'text-xsTitle'}
                      data={el?.documentDate}
                      dataType="Date"
                    />
                    <TextS
                      className="text-desc"
                      data={
                        navState?.paymentParty == 'Buyer'
                          ? el?.description
                          : navState?.paymentParty
                      }
                    />
                  </ColumnFlex>
                  <TextS className={'text-xsTitle'} data={el?.documentNo} />
                  <TextS
                    className="text-xsTitle"
                    data={el?.allocationAmount}
                    dataType="Number"
                    style={{ textAlign: 'center' }}
                  />
                </RowGrid>
              }
            />
          ))}
        </CardListHeader>
      )}
    </>
  )
}

export default PAprogressBillingDetail
