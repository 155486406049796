import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

interface props {
  control: any
  errors?: any
  isRequired?: boolean
  label: string
  name: string
  defaultValue?: any
  margin?: 'normal' | 'dense' | 'none'
}

export const TextFormField = ({
  control,
  errors,
  isRequired = true,
  label,
  name,
  defaultValue,
  margin = 'normal',
}: props) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    if (defaultValue) {
      setData(defaultValue)
    }
  }, [defaultValue])

  return (
    <Controller
      control={control}
      key={name}
      name={name}
      defaultValue={data}
      render={({ field: { onChange, onBlur, value } }) => (
        <TextField
          autoComplete="off"
          error={errors?.[name] ? true : false}
          fullWidth
          helperText={errors?.[name]?.message}
          label={`${label} ${isRequired ? '*' : ''}`}
          margin={margin}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          variant="standard"
        />
      )}
    />
  )
}
