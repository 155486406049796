import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { amtStr } from 'helpers/numFormatter'
import React, { CSSProperties } from 'react'

// Define a type for the props
interface TextProps {
  className?: string
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
  component?: React.ElementType
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
    | 'initial'
    | CSSProperties['color']
  // align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  // gutterBottom?: boolean
  // noWrap?: boolean
  style?: React.CSSProperties
  data?: any // New prop for dynamic content
  dataType?: 'Number' | 'Percentage' | 'Text' | 'Date' | 'Phone' | 'Email' // New prop for data type
  alt?: string // New prop for alternate content
  tooltip?: { title?: string; placement?: 'top' | 'bottom' | 'left' | 'right' }
}

// Define a custom Text component using forwardRef
const TextS = React.forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  const {
    // children,
    variant = 'inherit',
    component = 'span',
    color = '',
    // align = '',
    // gutterBottom = false,
    // noWrap = false,
    style = {},
    data,
    dataType = 'Text',
    alt = '-', // Provide a default value for alt
    // ...other
    tooltip,
    className,
  } = props

  let content

  if (data) {
    switch (dataType) {
      case 'Number':
        content = amtStr(data.toString())
        break
      case 'Percentage':
        content = `${amtStr(data.toString())}%`
        break
      case 'Text':
        content = data
        break
      case 'Date':
        const dateData = typeof data === 'number' ? data.toString() : data
        content = formatDate(dateData)
        break
      case 'Phone':
        content = <a href={`tel:${data}`}>{data}</a>
        break
      case 'Email':
        content = <a href={`mailto:${data}`}>{data}</a>
        break
      default:
        content = alt
    }
  } else {
    content = dataType === 'Number' ? amtStr(0) : alt
  }

  return (
    <>
      {tooltip && tooltip.title ? (
        <Tooltip
          title={tooltip.title}
          placement={tooltip.placement}
          disableInteractive
          enterTouchDelay={0}
        >
          <Typography
            className={className}
            ref={ref}
            variant={variant}
            component={component}
            color={color}
            // align={align}
            // gutterBottom={gutterBottom}
            // noWrap={noWrap}
            style={style}
            // {...other}
          >
            {content}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          className={className}
          ref={ref}
          variant={variant}
          component={component}
          color={color}
          // align={align}
          // gutterBottom={gutterBottom}
          // noWrap={noWrap}
          style={style}
          // {...other}
        >
          {content}
        </Typography>
      )}
    </>
  )
})

export default React.memo(TextS)
