import { ColumnFlex, Expansion, ExpansionSummary, RowFlex } from '@ifca-ui/core'
import { Typography } from '@mui/material'
import { useConfirmDialog } from 'components/Dialogs/useConfirmDialog'
import { CardListHeader } from 'components/General/CardListItem'
import useAppData from 'components/General/useAppData'
import { useState } from 'react'
import { useReversalPB } from './PAprogressBillingBloc'
import PBexpansionDetails from './PBexpansionDetails'
import PBexpansionSummary from './PBexpansionSummary'
interface Props {
  isPA: boolean
  type: string
  pbListData: any
  refetchData: any
}

export const PBtypeList = ({ isPA, type, pbListData, refetchData }: Props) => {
  const { saleID, navTo, projectID } = useAppData()
  const { reversalData } = useReversalPB(saleID, refetchData)
  const { showConfirmDialog, ConfirmDialogX } = useConfirmDialog()
  const [reversalId, setReversalId] = useState([])
  const filteredData = pbListData.filter(item => !item.isReversal)
  const lastIndex = filteredData.length - 1
  return (
    <>
      <ConfirmDialogX
        title={'Progress Billing'}
        bodyText={'Are you sure want to reversal?'}
        rightTopTitle={'Reversal'}
        action={() => {
          reversalData(reversalId)
        }}
      />
      <div>
        {pbListData.length >= 1 &&
          pbListData.map(data => {
            return (
              <div>
                <ColumnFlex>
                  <CardListHeader
                    title={
                      <Typography
                        className="text-xsTitle"
                        color="#FF9800"
                        component="span"
                      >
                        <RowFlex gap={'4px'}>
                          <div>
                            {type == 'SALE_PACKAGE'
                              ? 'Sales Package Progress Billing'
                              : 'Car Park Progress Billing'}
                          </div>
                          <div>-</div>
                          <div>
                            {type == 'SALE_PACKAGE'
                              ? data.salePackage
                              : data.carPark}
                          </div>
                        </RowFlex>
                      </Typography>
                    }
                  />
                  <ColumnFlex gap={'5px'}>
                    {data.progressBilling.map((item, index) => {
                      let haveSubData = item.details?.length > 1
                      const handleDelete = e => {
                        e.stopPropagation()
                        if (item.allow_reversal && !item.isReversal && isPA) {
                          const reversalIds = item.details
                            .map(data => data.progressBillingId)
                            .filter(Boolean)
                          setReversalId(reversalIds)
                          showConfirmDialog()
                        }
                      }

                      return (
                        <Expansion key={index} disabled={item.isReversal}>
                          <ExpansionSummary
                            className="clickable-item"
                            style={{ width: '100%' }}
                            onClick={() => {
                              if (!haveSubData) {
                                isPA
                                  ? navTo(
                                      `/${projectID}/purchaser-accounts/${saleID}/progress-billing/${item.details[0].progressBillingId}/view`,
                                      item.details[0]
                                    )
                                  : navTo(
                                      `/${projectID}/sales-contracts/${saleID}/submenu/progress-billing/${item.details[0].progressBillingId}/view`,
                                      item.details[0]
                                    )
                              }
                            }}
                          >
                            <PBexpansionSummary
                              item={item}
                              haveSubData={haveSubData}
                              isPA={isPA}
                              handleDelete={handleDelete}
                              lastIndex={lastIndex}
                              index={index}
                            />
                          </ExpansionSummary>
                          {haveSubData && <PBexpansionDetails item={item} />}
                        </Expansion>
                      )
                    })}
                  </ColumnFlex>
                </ColumnFlex>
              </div>
            )
          })}
      </div>
    </>
  )
}
