import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Mic, Stop } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'
import { Controller } from 'react-hook-form'

export const VoiceTextField = ({
  mounted,
  label,
  name,
  required,
  value,
  setValue,
  controllerProps,
  customOnchange,
  handleCustomOnChange,
  record,
  setRecord,
}: any) => {
  const [fieldName, setFieldName] = useState(null)

  const { transcript, listening, resetTranscript } = useSpeechRecognition()
  useEffect(() => {
    if (!!transcript || transcript !== '') {
      setValue(name, transcript)
    }
  }, [transcript])

  useEffect(() => {
    if (record) {
      resetTranscript()
      SpeechRecognition.startListening({ continuous: true })
    } else {
      SpeechRecognition.stopListening()
    }
  }, [record])

  if (mounted) {
    return (
      <Controller
        render={({ field: { onChange, value: renderValue } }) => (
          <TextField
            margin="normal"
            id="stardard-basic"
            variant="standard"
            label={label}
            name={name}
            {...controllerProps}
            fullWidth
            multiline
            required={required}
            value={renderValue}
            onChange={
              customOnchange
                ? handleCustomOnChange
                : e => {
                    onChange(e)
                  }
            }
            InputLabelProps={{
              shrink: !!renderValue,
            }}
            InputProps={{
              endAdornment:
                listening && fieldName === name ? (
                  <InputAdornment position="start">
                    <IconButton
                      style={{
                        paddingRight: '0px',
                      }}
                      onClick={e => {
                        if (listening) {
                          setRecord(false)
                          setFieldName('')
                        } else {
                          setRecord(true)
                        }
                      }}
                    >
                      <div style={{ color: 'red' }}>
                        <Stop />
                      </div>
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <Mic
                    onClick={() => {
                      setRecord(true)
                      setFieldName(name)
                    }}
                  />
                ),
            }}
          />
        )}
        name={name}
        {...controllerProps}
      />
    )
  } else return null
}
