import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Card, EmptyState, MenuItem, RowGrid } from '@ifca-ui/core'
import { AddBox } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers'
import {
  Autocomplete,
  Avatar,
  Box,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { Dialog } from 'components/Dialogs/Dialog'
import {
  DragNDropList,
  DraggableItem,
  DropableItem,
} from 'components/Dnd/DragAndDrop'
import { CardListHeader } from 'components/General/CardListItem'
import { ComputeMethod, useGetTaxSchemeTaxQuery } from 'generated/graphql'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { CommonYupValidation } from 'helpers/yup'
import React, { useEffect, useState } from 'react'
import {
  Controller,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { DatePickerX } from 'components/Inputs/DatePickerX'
import moment from 'moment'
import { getNextDate } from 'helpers/checkDatetime'

export interface TaxSchemeDetailForm {}

interface taxSchemaDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  dialogMode: string
  // itemDetailFields: FieldArrayWithId<any, 'details', 'detailId'>[]
  itemDetailFields: any
  itemDetailAppend: UseFieldArrayAppend<any, 'details'>
  itemDetailUpdate: UseFieldArrayUpdate<any, 'details'>
  defaultValue: any
  editIndex: number
}

export interface TaxSchemeDetailProps {
  effectiveDate: Date | string
  items: TaxSchemeDetailItemProps[]
  detailId: string | null
}

export interface TaxSchemeDetailItemProps {
  detailItemId: string | null
  seqNo: string | null
  tax: TaxObject
  computeMethod: string
  taxOnSeq?: any[] | null
}

interface TaxObject {
  taxId: string
  code: string
  rate: number
  schemeType: string
  classType: string
}

export const TaxSchemeDialogForm = ({
  open,
  setOpen,
  dialogMode,
  itemDetailFields,
  itemDetailAppend,
  itemDetailUpdate,
  defaultValue,
  editIndex,
}: taxSchemaDialogProps) => {
  const [taxList, setTaxList] = useState<TaxObject[]>([])

  //#region schema validation
  const schema = yup.object().shape({
    effectiveDate: CommonYupValidation.requireDate(
      'Effective Date is required'
    ).min(getNextDate(), 'Effective Date must be after today'),
    items: yup.array().of(
      yup.object().shape({
        tax: CommonYupValidation.requireArray('Tax is required'),
        computeMethod: CommonYupValidation.requireField(
          'Compute Method is required'
        ),
        taxOnSeq: yup.mixed().when('computeMethod', {
          is: value => value == ComputeMethod.TaxOnTaxedPrice,
          then: CommonYupValidation.requireMultiSelect(
            'Tax On Seq is required'
          ),
        }),
      })
    ),
  })
  //#endregion

  //#region init useform method
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    setValue,
    reset,
    watch,
  } = useForm<TaxSchemeDetailProps>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      effectiveDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      items: [] as any,
    },
  })
  //#endregion

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'items',
  })

  //--Start get tax dropdown--
  const {
    loading: getTaxSchemeTaxLoading,
    data: { getTaxSchemeTax } = {
      getTaxSchemeTax: [],
    },
  } = useGetTaxSchemeTaxQuery({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      let tempTaxList = data.getTaxSchemeTax?.map(x => {
        return {
          taxId: x.taxId,
          code: x.code,
          rate: x.rate,
          schemeType: x.schemeType,
          classType: x.classType,
        } as TaxObject
      })
      setTaxList(tempTaxList)
    },
  })
  //--End get tax dropdown--

  //#region reset form
  useEffect(() => {
    if (open && dialogMode == 'Add') {
      reset()
    }
  }, [open, dialogMode])

  //#endregion

  useEffect(() => {
    if (dialogMode == 'Edit' && defaultValue) {
      setValue('effectiveDate', defaultValue?.effectiveDate)
      setValue('items', defaultValue?.items)
    }
  }, [dialogMode, defaultValue])

  //#region on submit
  const onSubmit = (data: TaxSchemeDetailProps) => {
    if (data?.items?.length > 0) {
      const detailID =
        dialogMode === 'Add' ? uuidv4() : itemDetailFields[editIndex]?.detailId

      let classTypeChecking = data?.items[0]?.tax?.classType
      const checking = data?.items.filter(
        el => el.tax.classType !== classTypeChecking
      )

      if (checking?.length > 0) {
        setSnackBar('Cannot different class type')
      } else {
        const submitData = {
          detailId: detailID,
          effectiveDate: new Date(data?.effectiveDate)?.toISOString(),
          items: data?.items?.map((x, i) => {
            return {
              detailItemId: x.detailItemId,
              seqNo: x.seqNo,
              tax: x.tax,
              computeMethod: x.computeMethod,
              taxOnSeq: x.taxOnSeq?.map(z => {
                return {
                  detailItemId: z.detailItemId,
                  seqNo: z.seqNo,
                }
              }),
            }
          }),
        }

        if (dialogMode === 'Add') {
          itemDetailAppend(submitData)
        } else {
          itemDetailUpdate(editIndex, submitData)
        }
        setOpen(false)
      }
    } else {
      setSnackBar('Create at least (1) tax scheme detail item')
    }
  }
  //#endregion

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }
    const data = watch('items')
    const [removed] = data.splice(result.source.index, 1)
    data.splice(result.destination.index, 0, removed)

    data[0].computeMethod = ComputeMethod.TaxOnGross
    for (let i = 0; i < data.length; i++) {
      data[i].seqNo = (i + 1).toString()
      data[i].computeMethod = ''
      data[i].taxOnSeq = []
    }

    setValue('items', data)
  }

  const computeMethodData = [
    {
      value: ComputeMethod.TaxOnGross,
      name: 'Tax On Gross',
    },
    {
      value: ComputeMethod.TaxOnTaxedPrice,
      name: 'Tax On Taxed Price',
    },
  ]

  const taxOnSeqList = watch('items')

  return (
    <>
      <Dialog
        useDefaultDialogHeader={true}
        useDefaultDialogBody={false}
        open={open}
        defaultDialogData={{
          header: {
            title: {
              leftTopTitle: {
                title: {
                  text: dialogMode == 'Add' ? 'Add Tax' : 'Edit Tax',
                },
              },
            },
          },
        }}
        body={
          <>
            <Card>
              <DatePickerX
                control={control}
                name="effectiveDate"
                label="Effective Date"
                required
                helperText={errors?.effectiveDate?.message}
                error={!!errors?.effectiveDate}
                minDate={moment().add(1, 'days').toDate()}
              />
            </Card>

            <CardListHeader
              title={null}
              action={
                <IconButton
                  aria-label="Addbox"
                  style={{
                    padding: '0px',
                  }}
                  onClick={() => {
                    append({
                      detailItemId: uuidv4(),
                      seqNo: (fields?.length + 1)?.toString(),
                      tax: null,
                      computeMethod: '',
                      taxOnSeq: [],
                    })
                  }}
                >
                  <AddBox color="primary" />
                </IconButton>
              }
            >
              <>
                <Box width="100%" boxSizing="border-box" marginBottom="14px">
                  <Alert color="warning" icon={<></>}>
                    <Typography
                      component="span"
                      variant="inherit"
                      color="primary.dark"
                      className="text-xsTitle"
                    >
                      {
                        'Drag and drop the card to rearrange the sequence of Tax Scheme Detail Item.'
                      }
                    </Typography>
                  </Alert>
                </Box>
              </>
              {
                <DragNDropList onDragEnd={onDragEnd}>
                  <DropableItem droppableId={'droppable'}>
                    {fields === undefined || fields?.length === 0 ? (
                      <EmptyState
                        title={'No Records'}
                        subTitle={'Click add button to insert new records'}
                      />
                    ) : (
                      <>
                        {fields?.map((el, index) => {
                          const no = 1 + index
                          const compute = watch(`items.${index}.computeMethod`)

                          const tempList = fields?.filter(
                            x => Number(x.seqNo) < index + 1
                          )

                          return (
                            <DraggableItem
                              id={el?.id}
                              index={index}
                              draggableId={el?.id?.toString()}
                              secondaryAction={
                                <IconButton>
                                  <Avatar
                                    variant="rounded"
                                    sx={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: '#FF1B40',
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{
                                        width: '16px',
                                        height: '16px',
                                        color: '#fff',
                                      }}
                                      onClick={e => {
                                        remove(index)
                                      }}
                                    />
                                  </Avatar>
                                </IconButton>
                              }
                              children={
                                <>
                                  <RowGrid
                                    gridTemplateColumns={
                                      compute == ComputeMethod.TaxOnTaxedPrice
                                        ? '10px 1fr 1fr 1fr'
                                        : '10px 1fr 1fr'
                                    }
                                    fullWidth
                                    gap="10px"
                                    crossAxisAlignment="center"
                                  >
                                    <Typography marginRight="6px">
                                      {no}.
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name={`items.${index}.tax`}
                                      render={({
                                        field: { onChange, onBlur, value },
                                      }) => (
                                        <Autocomplete
                                          fullWidth
                                          options={
                                            itemDetailFields?.length == 0
                                              ? watch('items')[0]?.tax
                                                  ?.classType == null ||
                                                undefined
                                                ? taxList
                                                : taxList?.filter(
                                                    x =>
                                                      x.classType ==
                                                      watch('items')[0]?.tax
                                                        ?.classType
                                                  )
                                              : itemDetailFields[0]?.items[0]
                                                    ?.tax?.classType == null ||
                                                  undefined
                                                ? taxList
                                                : taxList?.filter(
                                                    x =>
                                                      x.classType ==
                                                      itemDetailFields[0]
                                                        ?.items[0]?.tax
                                                        ?.classType
                                                  )
                                          }
                                          getOptionLabel={option =>
                                            `${option?.['code']}/${option?.['rate']}%`
                                          }
                                          onChange={(event, newValue: any) => {
                                            onChange(newValue)
                                          }}
                                          value={value}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              label="Tax"
                                              variant="standard"
                                              margin="normal"
                                              autoComplete="off"
                                              value={`${value?.code}`}
                                              helperText={
                                                errors?.items?.[index]?.tax
                                                  ?.message
                                              }
                                              error={
                                                errors?.items?.[index]?.tax
                                                  ? true
                                                  : false
                                              }
                                              required
                                            />
                                          )}
                                        />
                                      )}
                                    />

                                    <Controller
                                      control={control}
                                      name={`items.${index}.computeMethod`}
                                      render={({
                                        field: { onChange, onBlur, value },
                                      }) => (
                                        <TextField
                                          select
                                          label="Compute Method"
                                          name={`items.${index}.computeMethod`}
                                          autoComplete="off"
                                          helperText={
                                            errors?.items?.[index]
                                              ?.computeMethod?.message
                                          }
                                          error={
                                            errors?.items?.[index]
                                              ?.computeMethod
                                              ? true
                                              : false
                                          }
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          fullWidth
                                          margin="normal"
                                          variant="standard"
                                          required
                                        >
                                          {index == 0 ? (
                                            <MenuItem
                                              value={ComputeMethod.TaxOnGross}
                                            >
                                              {'Tax On Gross'}
                                            </MenuItem>
                                          ) : (
                                            computeMethodData?.map(v => (
                                              <MenuItem value={v.value}>
                                                {v.name}
                                              </MenuItem>
                                            ))
                                          )}
                                        </TextField>
                                      )}
                                    />

                                    {compute ==
                                      ComputeMethod.TaxOnTaxedPrice && (
                                      <Controller
                                        control={control}
                                        name={`items.${index}.taxOnSeq`}
                                        render={({
                                          field: { onChange, onBlur, value },
                                        }) => (
                                          <Autocomplete
                                            multiple
                                            fullWidth
                                            disableCloseOnSelect
                                            getOptionLabel={option =>
                                              option?.['seqNo']
                                            }
                                            isOptionEqualToValue={(
                                              option,
                                              value
                                            ) =>
                                              option?.['detailItemId'] ===
                                              value?.['detailItemId']
                                            }
                                            options={tempList}
                                            onChange={(event, value) => {
                                              onChange(value)
                                            }}
                                            value={value}
                                            renderInput={params => (
                                              <TextField
                                                {...params}
                                                label="Tax On Seq"
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                value={value}
                                                helperText={
                                                  errors?.items?.[index]
                                                    ?.taxOnSeq?.message
                                                }
                                                error={
                                                  errors?.items?.[index]
                                                    ?.taxOnSeq
                                                    ? true
                                                    : false
                                                }
                                                required
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                    )}
                                  </RowGrid>
                                </>
                              }
                            />
                          )
                        })}
                      </>
                    )}
                  </DropableItem>
                </DragNDropList>
              }
            </CardListHeader>
          </>
        }
        footer={{
          buttons: [
            {
              children: 'Cancel',
              color: 'primary',
              onClick: () => {
                setOpen(false)
              },
            },
            {
              children: 'Save',
              color: 'primary',
              onClick: () => handleSubmit(onSubmit)(),
            },
          ],
        }}
      />
    </>
  )
}
