import { ListItemText } from '@ifca-ui/core'
import { Tooltip, Typography } from '@mui/material'
import { amtStr } from 'helpers/numFormatter'
import NumberFormat from 'react-number-format'

export interface firstLineLeft {
  backgroundColor?: string
  textColor?: string
  index?: number
  icon?: any
  text: string
}
export interface firstLine {
  left?: firstLineLeft
  right?: {
    icon?: any
    amount?: number
    color?: string
    text?: string
    className?: string
    tooltip?: string
  }
}

export interface secondLine {
  left?: {
    icon?: any
    text?: string
  }
  right?: {
    icon?: any
    amount?: number
    color: string
    text?: string
    tooltip?: string
  }
}

export interface thirdLine {
  left?: {
    icon?: any
    text?: string
  }
  right?: {
    icon?: any
    amount?: number
    color?: string
    text?: string
  }
}

export interface customLine {
  firstLine?: firstLine
  secondLine?: secondLine
  thirdLine?: thirdLine
}
export const PAdraftListComponent = ({
  firstLine,
  secondLine,
  thirdLine,
}: customLine) => {
  return (
    <>
      <ListItemText
        primary={
          <>
            <Typography
              color="common.black"
              component="div"
              display="flex"
              variant="inherit"
              flex="1"
            >
              <Typography
                className="text-icon"
                component="span"
                // variant="inherit"
                color={firstLine.left.textColor}
                style={{
                  backgroundColor: firstLine.left.backgroundColor,
                }}
              >
                {firstLine.left.index}
                {firstLine.left.icon}
              </Typography>
              <Typography
                component="span"
                className="text-desc"
                color="common.black"
                flex="1"
              >
                {firstLine.left.text}
              </Typography>

              {firstLine.right && (
                <Typography
                  className="text-desc"
                  component="span"
                  variant="inherit"
                >
                  <Typography
                    component="span"
                    variant="inherit"
                    className={`text-icon text-desc ${firstLine.right?.className}`}
                    color={firstLine.right.color}
                  >
                    {firstLine.right.tooltip ? (
                      <Tooltip title={firstLine.right.tooltip} placement="left">
                        <div>
                          {firstLine.right.icon}
                          {amtStr(firstLine.right.amount)}
                        </div>
                      </Tooltip>
                    ) : (
                      <div>
                        {firstLine.right.icon}
                        {amtStr(firstLine.right.amount)}
                      </div>
                    )}
                  </Typography>
                </Typography>
              )}
            </Typography>
          </>
        }
        secondary={
          <>
            {secondLine.left && (
              <Typography
                color="common.black"
                component="div"
                display="flex"
                variant="inherit"
              >
                <Typography component="span" flex="1" variant="inherit">
                  <Typography
                    className="text-desc text-icon"
                    component="span"
                    variant="inherit"
                    marginLeft={'18px'}
                  >
                    {secondLine.left.icon}
                    {secondLine.left.text}
                  </Typography>
                </Typography>
                {secondLine.right && (
                  <Typography
                    className="text-desc"
                    component="span"
                    variant="inherit"
                  >
                    <Typography
                      component="span"
                      variant="inherit"
                      className="text-icon text-desc"
                      color={secondLine.right.color ?? 'white'}
                    >
                      {secondLine.right.tooltip ? (
                        <Tooltip
                          title={secondLine.right.tooltip}
                          placement="left"
                        >
                          <div>
                            {secondLine.right.icon}
                            {secondLine.right.text}
                            <NumberFormat
                              value={secondLine.right.amount ?? 0}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <div>
                          {secondLine.right.icon}
                          {secondLine.right.text}
                          <NumberFormat
                            value={secondLine.right.amount ?? 0}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                        </div>
                      )}
                    </Typography>
                  </Typography>
                )}
              </Typography>
            )}

            {thirdLine?.left && (
              <Typography
                color="common.black"
                component="div"
                display="flex"
                variant="inherit"
              >
                <Typography component="span" flex="1" variant="inherit">
                  {/* dynamic info */}
                  <Typography
                    className="text-desc text-icon"
                    component="span"
                    variant="inherit"
                    marginLeft={'18px'}
                  >
                    {thirdLine.left.icon}
                    {thirdLine.left?.text}
                  </Typography>

                  {/* dynamic info */}
                </Typography>
                {thirdLine.right && (
                  <Typography
                    className="text-desc"
                    component="span"
                    variant="inherit"
                  >
                    {/* dynamic info */}
                    <Typography
                      component="span"
                      variant="inherit"
                      className="text-icon text-desc"
                      color={thirdLine.right.color}
                    >
                      {thirdLine.right.icon}
                      <NumberFormat
                        value={thirdLine.right.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </Typography>
                    {/* dynamic info */}
                  </Typography>
                )}
              </Typography>
            )}
          </>
        }
      />
    </>
  )
}
